import React from 'react'
import { Link } from "react-router-dom";
import './index.css';

export default class SelSearch extends React.Component {
  constructor (props) {
    super(props)
    let dropList = {};
    for (let i=0; i < props.options.length; i++) {
      let key = props.options[i].name + " (" + props.options[i].val + ")";
        dropList[key] = props.options[i].val;
    }
    this.state = {
      dropMenu: [],
      dropList: dropList
    }
  }

  render () {
    let dropNow = (e) => {
      e.preventDefault();
      selToggle(e.target.id);
      document.getElementById(e.target.id + "-search").value = "";
      document.getElementById(e.target.id + "-search").focus();
    }
    let closeNow = (e) => {
      e.preventDefault();
      let key = e.target.id
      selToggle(key.replace('-backdrop', ''));
    }
    let selectNow = (e) => {
      e.preventDefault();
      let eventID = e.target.id;
      let btnID = eventID.split("-link-");
      let key = btnID[0] + "-event";
      let value = e.target.attributes.value.value;
      selToggle(key);
      document.getElementById(btnID[0]).value = value;
      document.getElementById(key).innerHTML = e.target.innerHTML;
      if (this.props.method)
          this.props.method(value);
    }
    function selToggle(key) {
      document.getElementById(key + "-backdrop").classList.toggle("show");
      document.getElementById(key + "-area").classList.toggle("show");
    }

    let searchNow = (e) => {
      e.preventDefault();
      let eventID = e.target.id;
      let id = eventID.replace('-event-search', '');
      let input = document.getElementById(eventID);
      let filter = input.value.toUpperCase();
      let rows = 0; let item = [];
      for (const key in this.state.dropList) {
        if (key.toUpperCase().indexOf(filter) > -1) {
          item.push(<Link key={rows} to="#" id={id + "-link-" + rows} value={this.state.dropList[key]} onClick={selectNow}>{key}</Link>);
          rows++;
        }
        if (rows >= 10) {
          break;
        }
      }
      this.setState({dropMenu: item});
    }

    return (
      <div className={this.props.styling}>
        <label htmlFor={this.props.id}>{this.props.subject}</label>
        <input type="hidden" id={this.props.id} defaultValue={this.props.val} />
        <span id={this.props.id + "-event"} className="btn" onClick={dropNow}>{this.props.display}</span>
        <div id={this.props.id + "-event-backdrop"} className="select-backdrop" onClick={closeNow}></div>
        <div id={this.props.id + "-event-area"} className="dropdown-content">
          <input type="text" id={this.props.id + "-event-search"} onKeyUp={searchNow} placeholder="Search.." autoComplete="off" />
          <div id={this.props.id + "-event-search-ctx"}>
            {this.state.dropMenu}
          </div>
        </div>
      </div>
    )
  }
}