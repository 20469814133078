import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from "../../config/units.json";
import InputField from '../../components/InputField';
import OutputField from '../../components/OutputField';
import ContentAd from '../../components/ContentAd';
import './index.css';

const Cheque = () => {
  let chequeConvert = (e) => {
    e.preventDefault();
    let cheque = document.getElementById("cheque").value;
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(cheque)) {
      alert("無效金額");
      document.getElementById("zh_Hint").value = "";
      document.getElementById("en_US").value = "";
    } else {
      enUS(cheque);
      zhHint(cheque);
    }
  }

  let zhHint = (cheque) => {
    // Check Decimal
    cheque+= "00";
    let decLoc = cheque.indexOf('.');
    if (decLoc >= 0) {
        cheque = cheque.substring(0, decLoc) + cheque.substr(decLoc + 1, 2);
    }

    // Constants
    let unit = "仟佰拾億仟佰拾萬仟佰拾元角分"
        unit = unit.substr(unit.length - cheque.length);
    let str = "";
    for (let i=0; i<cheque.length; i++) {
        str+= "零壹貳叄肆伍陸柒捌玖".charAt(cheque.charAt(i)) + unit.charAt(i);
    }
    let result = str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零")
                    .replace(/零(萬|億|元)/g, "$1").replace(/(億)萬|壹(拾)/g, "$1$2")
                    .replace(/^元零?|零分/g, "").replace(/元$/g, "元正");
    document.getElementById("zh_Hint").value = result;
  }

  let enUS = (cheque) => {
    // Check Decimal
    let breakInt = cheque.split('.');
    let integer = breakInt[0];
    let decimal = (breakInt[1]) ? Number(breakInt[1]) : 0;
    let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    let double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    function tranInteger(n) {
      let word = ""
      if (n < 10) {word = single_digit[n] + ' ';}
      else if (n < 20) {word = double_digit[n - 10] + ' ';}
      else if (n < 100) {let rem = tranInteger(n % 10); word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;}
      else if (n < 1000) {word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + tranInteger(n % 100);}
      else if (n < 1000000) {word = tranInteger(parseInt(n / 1000)).trim() + ' Thousand ' + tranInteger(n % 1000);}
      else if (n < 1000000000) {word = tranInteger(parseInt(n / 1000000)).trim() + ' Million ' + tranInteger(n % 1000000);}
      else {word = tranInteger(parseInt(n / 1000000000)).trim() + ' Billion ' + tranInteger(n % 1000000000);}
      return word
    }
    function tranDecimal(n) {
      let word = "";
      if (n < 10) {word = single_digit[n] + ' ';}
      else if (n < 20) {word = double_digit[n - 10] + ' ';}
      else if (n < 100) {let rem = tranDecimal(n % 10); word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;}
      return word;
    }
    let result = tranInteger(integer);
        result+= 'Dollars ';
    if (decimal > 0) {
        result+= 'And ' + tranDecimal(decimal) + 'Cents ';
    }
        result+= 'Only';
    document.getElementById("en_US").value = result;
  }

  return (
    <>
      <Helmet>
        <title>2Box - {Config.cheque.title.zh}</title>
        <meta name="description" content={Config.cheque.desc.en + " " + Config.cheque.desc.zh} />
        <meta name="keywords" content={Config.cheque.keywords.en + ", " + Config.cheque.keywords.zh} />
        <link rel="canonical" href={"https://2box.app/" + Config.cheque.path} />
      </Helmet>
      <h1>{Config.cheque.title.zh}</h1>
      <form id="inputWrap" onSubmit={chequeConvert}>
        <InputField id="cheque" subject="支票金額" type="number" step="0.01" placeholder="0.00" />
      </form>
      <button type="submit" id="inputBtn" form="inputWrap">轉換 <FontAwesomeIcon icon="fa-solid fa-money-bill-transfer" /></button>

      <div id="outputWrap">
        <OutputField id="en_US" subject="英文大寫" styling="outputTab" />
        <OutputField id="zh_Hint" subject="中文大寫" styling="outputTab" />
      </div>

      <div id="descWrap">
        <ContentAd id="ctxAd" slot="/22938506537/content/content-web-1" loc="ctx-AD" styling="featureWrap" />
        <h3>支票小知識：</h3>
        <p>支票 (Cheque) 通常可以用中文或英文填寫，但由於中文收款人名稱、公司名稱及大寫數字筆劃多，容易寫錯，所以建議可用英文填寫。</p>
        <p>若以中文填寫支票，則金額必須要以數字大寫寫法填寫，如零、壹、貳、參、肆、伍、陸、柒、捌、玖、拾、佰、仟、萬、億。不得使用一、二、三、四、五、六、七、八、九、十、百、千這些寫法。</p>
        <h3>填寫支票：</h3>
        <ul>
          <ol><div>日期：</div><div>填寫開支的日期，此支票需要在此日期後才生效。</div></ol>
          <ol><div>祈付：</div><div>填寫收款人名稱或公司名稱，需要與其銀行戶口的名稱一致。</div></ol>
          <ol><div>港幣：</div><div>以中文數字大寫或英文填寫支票金額。亦有建議使用英文全部大寫的寫法，因為英文全部大寫的字母相對較難被更改。</div></ol>
          <ol><div>HK$：</div><div>以阿拉伯數字填寫支票金額。
            <ul>
              <li>為防止別人在你的金額的後面加數字，通常會在金額後寫「00/xx」，甚至可以再劃一條長線，確保沒有空間填更多的數字。</li>
              <li>除此以外，建議填寫數字時，使用千位分隔形式，適當地加入逗號，防止別人在你的金額的中間加數字。</li>
            </ul>
            </div>
          </ol>
          <ol><div>簽名：</div><div>於這位置簽署，簽名需要與銀行戶口的簽名一致。</div></ol>
        </ul>
        <h3>劃線：</h3>
        <p>支票上有地方會寫著「or bearer 或持票人」，如沒有劃去此項，任何人持有此支票都可以於銀行即時取得現金，無需核對持票人是否支票上填寫的收款人。如劃去此項，則必須核對名稱才可以過戶或取現金。
所以，在一般情況下，多數都會把「or bearer 或持票人」字眼劃去，避免不必要的損失。</p>
      </div>
    </>
  );
};

export default Cheque;