import './index.css';

const OutputField = ({id, subject, styling}) => {
  return (
    <div className={styling}>
      <label htmlFor={id}>{subject}</label>
      <textarea id={id} name={id} rows="2" readOnly></textarea>
    </div>
  )
};

export default OutputField;