import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Config from "./config/units.json";
import Wrapper from './pages/Wrapper';
import Home from './pages/Home';
import Column from './pages/Advanced/Column';
import CSV2Json from './pages/Advanced/CSV2Json';
import Duplicate from './pages/Advanced/Duplicate';
import Cheque from './pages/Converter/Cheque';
import Timezone from './pages/Converter/Timezone';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ContactUs from './pages/ContactUs';
import NoPage from './pages/NoPage';
import './index.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          <Route index element={<Home />} />
          <Route path={Config.column.path} element={<Column />} />
          <Route path={Config.csv2json.path} element={<CSV2Json />} />
          <Route path={Config.duplicate.path} element={<Duplicate />} />
          <Route path={Config.cheque.path} element={<Cheque />} />
          <Route path={Config.timezone.path + "/:zone1?/:zone2?"} element={<Timezone />} />
          <Route path={Config.timezone.path + "//:zone2?"} element={<Timezone />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);