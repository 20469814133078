import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Config from "../../config/units.json";
import Offset from "../../config/gmtoffset.json";
import Naming from "../../config/gmtName.json";
import Desc from "../../config/gmtDesc.json";
import TimeZone from "../../config/timezone.json";
import RevTimeZone from "../../config/revtimezone.json";
import InputField from '../../components/InputField';
import SelSearch from '../../components/SelSearch';
import ContentAd from '../../components/ContentAd';
import './index.css';

const Timezone = () => {
  /* 
   * General Converter
   */
  function getDate(date) {
    let mth = date.getMonth() + 1;
        mth = ("0" + mth).slice(-2);
    let dat = ("0" + date.getDate()).slice(-2);
    return date.getFullYear() + "-" + mth + "-" + dat;
  }
  function getTime(date) {
    return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
  }
  function getZone(offset) {
    let zone = "GMT";
    for (let key in Offset) {if (Offset[key] === offset) {zone = key; break;}}
    return zone;
  }

  // Get Existing Time Info.
  let now = new Date();
  let initDate = getDate(now);
  let initTime = getTime(now);
  let localOffset = now.getTimezoneOffset();
  let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get Manually Time Zone
  let {zone1, zone2} = useParams();
      zone1 = (zone1 && Naming[zone1.toUpperCase()] !== undefined) ? zone1.toUpperCase() : "";
      zone2 = (zone2 && Naming[zone2.toUpperCase()] !== undefined) ? zone2.toUpperCase() : "";
  // Setting Static Variable for SEO searching
  let have1 = (zone1) ? true : false;
  let selTitle = (zone1) ?? "Local";
  let selPath = (have1) ? "/" + zone1.toLowerCase() : "";
  let preMeta = (have1 && Naming[zone1]) ? zone1 + ", " + Naming[zone1] + ", " + zone1.toLowerCase() + ", " : "";
  let zoneDesc = (have1 && Desc[zone1]) ? Desc[zone1] +  " " : "";
  // Set Zone 1 to Local if haven't provided
  if (!zone1 || !Naming[zone1]) 
      zone1 = (RevTimeZone[localTimeZone]) ?? getZone(localOffset);
  let low1 = (zone1) ? zone1.toLowerCase() : "";
  let low2 = (zone2) ? zone2.toLowerCase() : "";

  // Set Left Side Info.
  let inOffSet = zone1;
  let inDisplay = (Naming[inOffSet]) ?? "Local Time";

  // Set Right Side Info.
  let tarOffSet = "";
  let tarDisplay = "";
  if (zone2) {
      tarOffSet = zone2;
      tarDisplay = Naming[zone2];
      selTitle+= " to " + zone2;
      selPath+= "/" + low2;
      preMeta+= zone2 + ", " + tarDisplay + ", " + low2;
      preMeta+= (have1) ? ", " + zone1 + " to " + zone2 + ", " + low1 + " to " + low2 : ", Local to " + zone2 + ", local to " + low2;
      zoneDesc+= (Desc[zone2]) ? Desc[zone2] +  " " : "";
  }
  
  /* 
   * Execute the Converter
   */
  function convertTimeZone() {
    let actTime = document.getElementById("time").value;
    let actDate = document.getElementById("date").value;
    let actZone = document.getElementById("zone").value;
    let pasZone = document.getElementById("zoneTar").value;

    // Default Handling
    let actZoneEvent = document.getElementById("zone-event").innerHTML;
    let isLocal = (actZoneEvent === "Local Time") ? true : false;

    if (actTime && actDate && actZone && pasZone) {
      convertExecute(actTime, actDate, actZone, pasZone, isLocal);
    }

    // Selection handling
    let selector2 = (pasZone && Naming[pasZone] !== undefined) ? true : false;
    if (actZone && !isLocal) {
      timeZoneDesc("zone1Desc", actZone);
      otherTimezone(actZone);
      if (selector2 && Naming[actZone] !== undefined) {
        document.getElementById("more").innerHTML = actZone + " to " + pasZone;
      }
    }
    if (selector2) {
      timeZoneDesc("zone2Desc", pasZone);
    }
  }

  function convertExecute(actTime, actDate, actZone, pasZone, isLocal) {
    // Convert Zone to number
    let actNum = Offset[actZone] ?? 0;
    let pasNum = Offset[pasZone] ?? 0;
    let difNum = actNum - pasNum;
    if (difNum === 0) {
      document.getElementById("timeTar").value = actTime;
      document.getElementById("dateTar").value = actDate;
    } else {
      let difHrs = Math.trunc(difNum / 60);
      let difMin = difNum % 60;

      // Prepare Calc Value
      let bkTime = actTime.split(":");
      let actHrs = Number(bkTime[0]);
      let actMin = Number(bkTime[1]);

      // Calc Target Value
      let tarHrs = actHrs + difHrs;
      let tarMin = actMin + difMin;
      let bkDate = actDate.split("-");
      let newDate = new Date(bkDate[0], (Number(bkDate[1]) - 1), Number(bkDate[2]), tarHrs, tarMin);

      // Show the Target Date Time
      document.getElementById("timeTar").value = getTime(newDate);
      document.getElementById("dateTar").value = getDate(newDate);
    }

    // Display time List
    timeDifference(actZone, pasZone, actDate, difNum, isLocal);
  }

  
  /* 
   * Page Content Setting
   */
  // Time Period Compare Table
  function timeDifference(zone, target, actDate, difNum, isLocal = false) {
    let lZone = (isLocal) ? "Local" : zone;
    let difHrs = Math.trunc(difNum / 60);
    let difMin = difNum % 60;
    let bkDate = actDate.split("-");
    let result = `<h3>Time Difference</h3>`;
        result+= `<table class="featureList" cellspacing="0">`;
        result+= `<tr><th>${lZone}</th><th>${target}</th></tr>`;
    for (let i = 0; i < 24; i++) {
        let left = ("0" + i).slice(-2) + ":00";
        let leftClass = getBackgroud(i);
        let tarHrs = i + difHrs;
        let tarMin = 0 + difMin;
        let right = new Date(bkDate[0], (Number(bkDate[1]) - 1), Number(bkDate[2]), tarHrs, tarMin);
        let rightClass = getBackgroud(tarHrs);
        result+= `<tr><td class="${leftClass}">${actDate} ${left}</td><td class="${rightClass}">${getDate(right)} ${getTime(right)}</td></tr>`;
    }
        result+= `</table>`;
    document.getElementById("timeDiff").innerHTML = result;
  }
  // Find the background base on time
  function getBackgroud(hour) {
    if (hour < 0) {hour = 24 + hour;}
    let night = [0, 19, 20, 21, 22, 23, 24];
    let earlyMorning = [1, 2, 3, 4, 5, 6];
    let morning = [7, 8, 9, 10, 11, 12];
    // let afternoon = [13, 14, 15, 16, 17, 18];
    if (night.includes(hour)) {return "night";}
    else if (earlyMorning.includes(hour)) {return "earlyMorning";}
    else if (morning.includes(hour)) {return "morning";}
    else {return "afternoon";}
  }
  // Other timezone Link Lists
  function otherTimezone(zone) {
    let result = `<h3>Convert ${zone} to other time zones</h3>`;
        result+= `<div class="flexContainer">`;
    for (let key in Naming) {
      if (key !== zone) {
        result+= `<a href="/${Config.timezone.path}/${zone.toLowerCase()}/${key.toLowerCase()}" class="flexItem">${zone} to ${key}</a>`;
      }
    }
        result+= `</div>`;
    document.getElementById("otherTime").innerHTML = result;
  }
  // Display Timezone Desc
  function timeZoneDesc(id, zone) {
    document.getElementById(id).innerHTML = (Desc[zone]) ? `<h3>${zone}</h3><div>${Desc[zone]}</div>` : "";
  }


  /* 
   * Event base convert the time
   */
  // Input Field onChange / Select Field
  let timeSelect = (value = "") => {
    convertTimeZone();
  }
  // Preload
  useEffect(convertTimeZone);

  return (
    <>
      <Helmet>
        <title>2Box - {Config.timezone.title.en} {selTitle}</title>
        <meta name="description" content={Config.timezone.desc.en + " " + zoneDesc + Config.timezone.desc.zh} />
        <meta name="keywords" content={Config.timezone.keywords.en + ", " + Config.timezone.keywords.zh + ", " + preMeta} />
        <link rel="canonical" href={"https://2box.app/" + Config.timezone.path + selPath} />
      </Helmet>
      <h1>{Config.timezone.title.en} <span id="more">{selTitle}</span></h1>
      <div id="onelineInOut">
        <form id="inputWrap">
          <InputField id="time" subject="Time" type="time" styling="borderWrapCtx col-2" val={initTime} method={timeSelect} />
          <InputField id="date" subject="Date" type="date" styling="borderWrapCtx col-2" val={initDate} method={timeSelect} />
          <SelSearch id="zone" subject="Time Zone" styling="selectWrapCtx col-1" options={TimeZone} method={timeSelect} val={inOffSet} display={inDisplay} />
        </form>
        <hr/>
        <div id="outputWrapFlex">
          <InputField id="timeTar" subject="Time" type="time" styling="borderWrapCtx col-2 read" read="true" />
          <InputField id="dateTar" subject="Date" type="date" styling="borderWrapCtx col-2 read" read="true" />
          <SelSearch id="zoneTar" subject="Time Zone" styling="selectWrapCtx col-1" options={TimeZone} method={timeSelect} val={tarOffSet} display={tarDisplay} />
        </div>
      </div>

      <div id="descWrap">
        <ContentAd id="ctxAd" slot="/22938506537/content/content-web-1" loc="ctx-AD" styling="featureWrap" />
        <div id="zone1Desc" className="featureWrap"></div>
        <div id="zone2Desc" className="featureWrap"></div>
        <div id="timeDiff" className="featureWrap"></div>
        <div id="otherTime" className="featureWrap"></div>
        <h3>Names of time zones:</h3>
        <p>The time zones have unique names in the form "Area/Location", e.g. "America/New_York". A choice was also made to use English names or equivalents, and to omit punctuation and common suffixes. The underscore character is used in place of spaces. Hyphens are used where they appear in the name of a location. The Area and Location names have a maximum length of 14 characters.</p>
        <h3>時區名稱:</h3>
        <p>時區具有“區域/位置”形式的唯一名稱，例如 “美國/紐約”。 還選擇使用英文名稱或等效名稱，並省略標點符號和常用後綴。 下劃線字符用於代替空格。 連字符用於它們出現在位置名稱中的位置。 區域和位置名稱的最大長度為 14 個字符。</p>
      </div>
    </>
  );
};

export default Timezone;