import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Config from "../../config/units.json";
import './index.css';

const Home = () => {
  let life = [];
  let advance = [];
  for (const idx in Config) {
    if (Config[idx]['mobile']) {
      life.push(<Link key={idx} to={"/" + Config[idx]['path']}>{Config[idx]['title']['en']}</Link>);
    } else {
      advance.push(<Link key={idx} to={"/" + Config[idx]['path']}>{Config[idx]['title']['en']}</Link>);
    }
  }
  return (
    <>
      <Helmet>
        <title>2Box - Online Converter - Easier and Faster</title>
        <meta name="description" content="Step-by-step tutorial to get you started with JavaScript. Or discover our top online tools that can help you save time and increase productivity" />
        <meta name="keywords" content="Step by Step, tutorial, Javascript tutuorial, JavaScript, JS, javascript, programming" />
        <link rel="canonical" href="https://2box.app/" />
      </Helmet>
      <h3 id="welcome">Welcome to <Link to="/">2Box</Link>, here's a step-by-step tutorial to get you started with JavaScript! Or using below online converter instead developing by yourself.</h3>
      <h1>General (Mobile view friendly)</h1>
      <div className="toolLists">
        {life}
      </div>
      <h1>Advanced</h1>
      <div className="toolLists">
        {advance}
      </div>
      <h4>JavaScript is a popular programming language used primarily for developing web applications. It is a versatile language that runs on the client-side (in the web browser) as well as the server-side (with the help of frameworks like Node.js).</h4>
      
      <h4>Step 1: Setting Up</h4>
      <p className="desc">To write JavaScript code, you'll need a text editor and a web browser. Popular text editors include Visual Studio Code, Sublime Text, or Atom. Once you have a text editor, create an HTML file (e.g., index.html) and open it in a web browser.</p>
      
      <h4>Step 2: Adding JavaScript to HTML</h4>
      <p className="desc">Inside your HTML file, add a <code>`&lt;script&gt;`</code> tag to include your JavaScript code. You can either place it in the <code>`&lt;head&gt;`</code> section or just before the closing <code>`&lt;/body&gt;`</code> tag. For example:</p>
      <div className="codeWrap">
        <div className="title">html</div>
        <div className="ctx">
          <code>
            <span className="code-met code-next">&lt;!DOCTYPE <span className="code-keyword">html</span>&gt;</span>
            <span className="code-tag code-next">&lt;html&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;head&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;title&gt;JavaScript Tutorial&lt;/title&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;script  <span className="code-attr">src</span>=<span className="code-string">"script.js"</span>&gt;&lt;/script&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;/head&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;body&gt;</span>
            <span className="code-comment code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- Your HTML content here --&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;/body&gt;</span>
            <span className="code-tag">&lt;/html&gt;</span>
          </code>
        </div>
      </div>

      <h4>Step 3: Writing JavaScript Code</h4>
      <p className="desc">Create a new file in the same directory as your HTML file and name it "script.js" (or any other name you prefer). This is where you'll write your JavaScript code.</p>
      <p className="desc">For starters, let's write a simple "Hello, World!" program:</p>
      <div className="codeWrap">
        <div className="title">javascript</div>
        <div className="ctx">
          <code>
            <span className="code-comment code-next">&#47;&#47; script.js</span>
            <span className="code-tag"><span className="code-attr">console</span>.<span className="code-attr">log</span>(<span className="code-string">"Hello, World!"</span>);</span>
          </code>
        </div>
      </div>
      <p className="desc">Save the file, and when you refresh your HTML page in the browser, you should see the message "Hello, World!" in the browser's console.</p>

      <h4>Step 4: JavaScript Basics</h4>
      <p className="desc">JavaScript is a dynamically typed language, which means you don't need to specify variable types explicitly. Here's an example of variable declaration and usage:</p>
      <div className="codeWrap">
        <div className="title">javascript</div>
        <div className="ctx">
          <code>
            <span className="code-comment code-next">&#47;&#47; script.js</span>
            <span className="code-tag code-next"><span className="code-var">let</span> greeting = <span className="code-string">"Hello"</span>;</span>
            <span className="code-tag code-next"><span className="code-var">let</span> name = <span className="code-string">"John"</span>;</span>
            <span className="code-tag code-next"><span className="code-var">let</span> message = greeting + <span className="code-string">", "</span> + name + <span className="code-string">"!"</span>;</span>
            <span className="code-tag"><span className="code-attr">console</span>.<span className="code-attr">log</span>(message);</span>
          </code>
        </div>
      </div>

      <h4>Step 5: Interacting with HTML Elements</h4>
      <p className="desc">JavaScript allows you to manipulate HTML elements and respond to user actions. You can access HTML elements using their <code>`id`</code> attribute and perform various operations on them. Here's an example that changes the text of a <code>`&lt;p&gt;`</code> element when a button is clicked:</p>
      <div className="codeWrap">
        <div className="title">html</div>
        <div className="ctx">
          <code>
            <span className="code-met code-next">&lt;!DOCTYPE <span className="code-keyword">html</span>&gt;</span>
            <span className="code-tag code-next">&lt;html&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;head&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;title&gt;JavaScript Tutorial&lt;/title&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;script  <span className="code-attr">src</span>=<span className="code-string">"script.js"</span>&gt;&lt;/script&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;/head&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;body&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;p <span className="code-attr">id</span>=<span className="code-string">"my-paragraph"</span>&gt;Original Text&lt;/p&gt;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;&nbsp;&nbsp;&lt;button <span className="code-attr">onclick</span>=<span className="code-string">"changeText&#40;&#41;"</span>&gt;Click Me&lt;/button&gt;</span>          
            <span className="code-tag code-next">&nbsp;&nbsp;&lt;/body&gt;</span>
            <span className="code-tag">&lt;/html&gt;</span>
          </code>
        </div>
      </div>
      <div className="codeWrap">
        <div className="title">javascript</div>
        <div className="ctx">
          <code>
            <span className="code-comment code-next">&#47;&#47; script.js</span>
            <span className="code-tag code-next"><span className="code-var">function</span> <span className="code-attr">changeText</span>&#40;&#41; &#123;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;<span className="code-var">let</span> paragraph = <span className="code-attr">document</span>.<span className="code-attr">getElementById</span>&#40;<span className="code-string">"my-paragraph"</span>&#41;;</span>
            <span className="code-tag code-next">&nbsp;&nbsp;paragraph.textContent = <span className="code-string">"New Text"</span>;</span>
            <span className="code-tag">&#125;</span>
          </code>
        </div>
      </div>
      <p className="desc">In this example, when the button is clicked, the <code>`changeText&#40;&#41;`</code> function is called. It retrieves the <code>`&lt;p&gt;`</code> element by its <code>`id`</code> and updates its <code>`textContent`</code> property.</p>
      <p className="desc">These are just the basics of JavaScript. There is a wide range of concepts and techniques to explore, including control structures (if-else statements, loops), functions, objects, arrays, and more. You can find comprehensive tutorials and resources on websites like Mozilla Developer Network (MDN) or W3Schools to continue your JavaScript learning journey.</p>
      <p className="desc">Remember to practice and experiment with code to solidify your understanding. Happy coding!</p>
    </>
  );
};

export default Home;