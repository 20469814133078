import React from 'react'
import { Helmet } from "react-helmet";
import _ from 'lodash'
import ReactDataSheet from 'react-datasheet';
import Config from "../../config/units.json";
import InputArea from '../../components/InputArea';
import 'react-datasheet/lib/react-datasheet.css';
import './index.css';


export default class Duplicate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      grocery: [[], [], [], [], []]
    }
  }

  generateGrid () {
    let rows = [[{readOnly: true, value: ''}, {readOnly: true, value: 'A'}, {readOnly: true, value: 'B'}]]
    for(let i = 0; i < this.state.grocery.length; i++) {
      rows.push([{readOnly: true, value: i + 1}, {value: this.state.grocery[i][0]}, {value: this.state.grocery[i][1]}]);
    }
    return rows
  }

  render () {
    let dupCount = (e) => {
      e.preventDefault();
      let rawData = document.getElementById("left-field").value;
      let newline = document.getElementById("separator").value;
      let cells;
      if (!rawData || !newline) {
       alert("Error: Please fill the data!");
      } else {
        if (newline === "newline") {
          cells = rawData.split("\n");
        } else if (newline === "tab") {
          cells = rawData.split("\t");
        } else if (newline === "comma") {
          cells = rawData.split(",");
        } else if (newline === "semicolon") {
          cells = rawData.split(";");
        }
        startCount(cells);
      }
    }

    let startCount = (cellArr) => {
      let keywords = {};
      for (let i = 0; i < cellArr.length; i++) {
        if (!keywords[cellArr[i]]) {
          keywords[cellArr[i]] = 0;
        }
        keywords[cellArr[i]]++;
      }
      // Add grouped key to state
      let rows = 0;
      for (const key in keywords) {
        this.setState({grocery: _.assign(this.state.grocery, {[rows]: [key, keywords[key]]})});
        rows++;
      }
    }

    return (
      <>
        <Helmet>
          <title>2Box - {Config.duplicate.title.en}</title>
          <meta name="description" content={Config.duplicate.desc.en + " " + Config.duplicate.desc.zh} />
          <meta name="keywords" content={Config.duplicate.keywords.en + ", " + Config.duplicate.keywords.zh} />
          <link rel="canonical" href={"https://2box.app/" + Config.duplicate.path} />
        </Helmet>
        <h1>{Config.duplicate.title.en}</h1>
        <div id="ctxWrap">
          <InputArea id="left-field" subject="Paste Wording" styling="areaField" />
          <div id="actWrap">
            <div className="ctx">
              <p>
                <label htmlFor="separator">Separator</label>
                <select id="separator">
                <option value="newline">New Line</option>
                <option value="tab">Tab</option>
                <option value="comma">Comma</option>
                <option value="semicolon">Semi-Colon</option>
                </select>
              </p>
              <p><button id="match-btn" onClick={dupCount} type="button">Count</button></p>
            </div>
          </div>
          <div id="resultWrap" className="areaField max">
            <h3>Result</h3>
            <ReactDataSheet
              data={this.generateGrid()}
              valueRenderer={cell => cell.value ?? ''}
              onCellsChanged={() => {}}
            />
          </div>
        </div>
        
        <div id="descWrap">
          <h3>Thanks</h3>
          <p className="desc">Our website is designed to make your online conversion experience as seamless as possible. Simply select the tool you need from our homepage, upload your file or input the information required, and let our tool handle the rest. Our online converter tools are fast, accurate, and reliable, ensuring that you get the results you need in no time.</p>
          <p className="desc">Thank you for choosing 2Box for your online conversion needs. We look forward to serving you!</p>
        </div>
      </>
    )
  }
}