import { Helmet } from "react-helmet";
import { Outlet, Link } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faBars, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import Nav from '../../components/Nav';
import ContentAd from '../../components/ContentAd';
import Logo from '../../assets/images/logo.png';
import './index.css';

library.add(faBars, faMoneyBillTransfer, faCircleXmark)

const Wrapper = () => {
  return (
    <>
      <Helmet>
        <title>2Box - Online Tools - Easier and Faster</title>
        <meta name="description" content="Online tools have become an essential part of our daily lives. They make our work easier, faster, and more convenient. Discover the top online tools that can help you save time and increase productivity."/>
        <meta name="keywords" content="online tools, tools, toolbox, online, matching, mapping, converter, 網上工具, 工具, 兌"/>
        <link rel="canonical" href="https://2box.app/" />
      </Helmet>
      <header>
        <section>
          <Link to="/" className="logo"><img src={Logo} alt="2Box Logo" /></Link>
          <Nav />
        </section>
      </header>

      <main>
        <section>
          <ContentAd id="leftSideAd" slot="/22938506537/side/side-left" loc="side-AD" styling="left-side-banner" />
          <Outlet />
          <ContentAd id="rightSideAd" slot="/22938506537/side/side-right" loc="side-AD" styling="right-side-banner" />
        </section>
      </main>

      <footer>
        <section>
          <ul>
            <li key="terms"><Link to="/terms">Terms</Link></li>
            <li key="privacy"><Link to="/privacy">Privacy</Link></li>
            <li key="contactus"><Link to="/contactus">Contact Us</Link></li>
          </ul>
          <div>Copyright © 2023 <Link to="/">2Box</Link></div>
        </section>
        <ContentAd id="footerAd" slot="/22938506537/Footer/footer-web" loc="footer-AD" styling="footer-anchor" />
      </footer>
    </>
  )
};

export default Wrapper;