const ContentAd = ({id, slot, loc, styling}) => {

  let eleID = `${id}_${Date.now()}`;
  let googleDone = false;
  let googleInit = setTimeout(() => {
    if (googleDone) {
      cancelInit();
    } else if (typeof window !== 'undefined') {
      googleDone = true;
      readyAd();
    }
  }, 500);

  function readyAd() {
    let googletag = window.googletag || {cmd: []};
    if (loc !== "side-AD" || document.body.clientWidth >= 960) {
      googletag.cmd.push(function() {
        googletag.defineSlot(slot, adSize(loc), eleID).addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
        googletag.display(eleID);
      });
    }
  }

  function adSize(loc) {
    if (loc === "ctx-AD") {
      return (document.body.clientWidth > 728) ? [[728, 90], [320, 100]] : [[320, 100]];
    } else if (loc === "footer-AD") {
      let size = [];
      if (document.body.clientWidth > 980) {size.push([980, 90]);}
      if (document.body.clientWidth > 970) {size.push([970, 90]);}
      if (document.body.clientWidth > 960) {size.push([960, 90]);}
      if (document.body.clientWidth > 728) {size.push([728, 90]);}
          size.push([320, 100]);
      return size;
    } else if (loc === "side-AD") {
      return [[160, 600], [120, 600], [120, 240], [125, 125]];
    } else {
      return [[1, 1,]];
    }
  }

  function cancelInit() {
    clearTimeout(googleInit);
  }

  return (
    <div id={eleID} className={styling}></div>
  )
};

export default ContentAd;