import { Helmet } from "react-helmet";
import Config from "../../config/units.json";
import InputArea from '../../components/InputArea';
import './index.css';

const CSV2Json = () => {

  let csvConvert = (e) => {
    e.preventDefault();
    let rawData = document.getElementById("left-field").value;
    let newline = document.getElementById("separator").value;
    let jsonData = [];
    let headers = [];
    let rows = rawData.split("\n");
    for (let i = 0; i < rows.length; i++) {
      let cells;
      if (newline === "comma") {
        cells = rows[i].split(",");
      } else if (newline === "semicolon") {
        cells = rows[i].split(";");
      } else {
        cells = rows[i].split("\t");
      }
      let rowData = {};
      for (let j=0; j<cells.length; j++){
        if (i === 0) {
          headers.push(cells[j].trim());
        } else {
          if (headers[j]) {
            rowData[headers[j]] = cells[j].trim();
          }
        }
      }
      //skip the first row (header) data
      if (i !== 0) {
        jsonData.push(rowData);
      }
    }
      
    //displaying the json result in string format
    document.getElementById("right-field").value = JSON.stringify(jsonData, null, 4);
  }

  return (
    <>
      <Helmet>
        <title>2Box - {Config.csv2json.title.en}</title>
        <meta name="description" content={Config.csv2json.desc.en + " " + Config.csv2json.desc.zh} />
        <meta name="keywords" content={Config.csv2json.keywords.en + ", " + Config.csv2json.keywords.zh} />
        <link rel="canonical" href={"https://2box.app/" + Config.csv2json.path} />
      </Helmet>
      <h1>{Config.csv2json.title.en}</h1>
      <div id="ctxWrap">
        <InputArea id="left-field" subject="Paste CSV" styling="areaField" />
        <div id="actWrap">
          <div className="ctx">
            <p>
              <label htmlFor="separator">Separator</label>
              <select id="separator">
              <option value="tab">Tab</option>
              <option value="comma">Comma</option>
              <option value="semicolon">Semi-Colon</option>
              </select>
            </p>
            <p><button id="match-btn" onClick={csvConvert} type="button">Convert</button></p>
            <h5 className="hints">Hints:</h5>
            <ul className="hint-lists">
              <li>First line will be a object key</li>
              <li>Separate row by a new line</li>
            </ul>
          </div>
        </div>
        <InputArea id="right-field" subject="JSON" styling="areaField" read="true" />
      </div>
      
      <div id="descWrap">
        <h3>Thanks</h3>
        <p className="desc">Our website is designed to make your online conversion experience as seamless as possible. Simply select the tool you need from our homepage, upload your file or input the information required, and let our tool handle the rest. Our online converter tools are fast, accurate, and reliable, ensuring that you get the results you need in no time.</p>
        <p className="desc">Thank you for choosing 2Box for your online conversion needs. We look forward to serving you!</p>
      </div>
    </>
  );
};

export default CSV2Json;