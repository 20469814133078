import './index.css';

const InputArea = ({id, subject, styling, read = false}) => {
  return (
    <div className={styling}>
      <h3>{subject}</h3>
      <textarea id={id} readOnly={read ? true : false}></textarea>
    </div>
  )
};

export default InputArea;