import React from 'react'
import { Helmet } from "react-helmet";
import _ from 'lodash'
import ReactDataSheet from 'react-datasheet';
import { CSVLink } from "react-csv";
import Config from "../../config/units.json";
import InputArea from '../../components/InputArea';
import 'react-datasheet/lib/react-datasheet.css';
import './index.css';

export default class Column extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      grocery: [[], [], []]
    }
  }

  generateGrid () {
    let rows = [[{readOnly: true, value: ''}, {readOnly: true, value: 'A'}, {readOnly: true, value: 'B'}, {readOnly: true, value: 'C'}]]
    for(let i = 0; i < this.state.grocery.length; i++) {
      rows.push([{readOnly: true, value: i + 1}, {value: this.state.grocery[i][0]}, {value: this.state.grocery[i][1]}, {value: this.state.grocery[i][2]}]);
    }
    return rows
  }

  render () {
    let headers = [{label: "Main Column"}, {label: "Status"}, {label: "Sub Column"}];

    let colsMatch = () => {
      let leftField = document.getElementById("left-field").value;
      let rightField = document.getElementById("right-field").value;
      if (!leftField || !rightField) {
       alert("Error: Please fill the data!");
      } else {
        // Prepare Data
        matchContinue(covertArr(leftField), covertArr(rightField));
      }
    }

    let covertArr = (val) => {
      return val.split("\n");
    }

    let matchContinue = (leftArr, rightArr) => {
      for(let i = 0; i < leftArr.length; i++) {
        let judge = rightArr.indexOf(leftArr[i]);
        if (!this.state.grocery[i]) {
          this.setState({grocery: _.assign(this.state.grocery, {[i]: []})})
        }
        this.setState({grocery: _.assign(this.state.grocery, {[i]: [leftArr[i], (judge < 0) ? 'Un-Matched' : 'Matched', (rightArr[judge]) ?? '']})});
      }
      document.getElementsByClassName("resultBtn")[0].style.display = 'block';
    }

    return (
      <>
        <Helmet>
          <title>2Box - {Config.column.title.en}</title>
          <meta name="description" content={Config.column.desc.en + " " + Config.column.desc.zh} />
          <meta name="keywords" content={Config.column.keywords.en + ", " + Config.column.keywords.zh} />
          <link rel="canonical" href={"https://2box.app/" + Config.column.path} />
        </Helmet>
        <h1>{Config.column.title.en}</h1>
        <div id="ctxWrap">
          <InputArea id="left-field" subject="Main Column" styling="areaField" />
          <div id="actWrap">
            <div className="ctx">
              <p><button id="match-btn" onClick={colsMatch} type="button">Match</button></p>
              <h5 className="hints">Hints:</h5>
              <ul className="hint-lists">
                <li>Don't put <b>"</b> or <b>\</b> in each row</li>
                <li>Separate row by a new line</li>
              </ul>
            </div>
          </div>
          <InputArea id="right-field" subject="Sub Column" styling="areaField" />
        </div>

        <div id="resultWrap">
          <h3>Result</h3>
          <CSVLink filename={"colMatch.csv"} className="resultBtn" data={this.state.grocery} headers={headers}>Download CSV</CSVLink>
          <ReactDataSheet
            data={this.generateGrid()}
            valueRenderer={cell => cell.value ?? ''}
            onCellsChanged={() => {}}
          />
        </div>

        <div id="descWrap">
          <h3>Thanks</h3>
          <p className="desc">Our website is designed to make your online conversion experience as seamless as possible. Simply select the tool you need from our homepage, upload your file or input the information required, and let our tool handle the rest. Our online converter tools are fast, accurate, and reliable, ensuring that you get the results you need in no time.</p>
          <p className="desc">Thank you for choosing 2Box for your online conversion needs. We look forward to serving you!</p>
        </div>
      </>
    )
  }
}