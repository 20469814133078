import './index.css';

const InputField = ({id, subject, type, styling = "inputWrapCtx", step = "any", placeholder = "", val = "", read = false, method = () => {}}) => {
  return (
    <div className={styling}>
      <label htmlFor={id}>{subject}</label>
      <input 
        type={type} 
        id={id} 
        name={id} 
        step={step} 
        placeholder={placeholder} 
        defaultValue={val} 
        readOnly={read ? true : false}
        onChange={method}
      />
    </div>
  )
};

export default InputField;