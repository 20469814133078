import { Link } from "react-router-dom";

const ContactUs = () => {

  return (
    <>
    	<h2>Contact Us</h2>
      <p>We would love to hear from you! If you have any questions, suggestions, or feedback, please don't hesitate to get in touch with us. Our customer service team is available 24/7 to assist you with any inquiries you may have.</p>
      <p>Thank you for using our online converter website <Link to="/">2Box</Link>, and we look forward to hearing from you!</p>
      <p>Email: <Link to="mailto:info@2box.app">info@2box.app</Link>.</p>
    </>
  );
};

export default ContactUs;