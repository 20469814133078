import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from "../../config/units.json";
import './index.css';

const Nav = () => {
  function hideByClick() {
    document.getElementById("header-nav-backdrop").classList.remove("show");
    document.getElementById("header-nav-area").classList.remove("show");
  }

  let life = [];
  let advance = [];
  for (const idx in Config) {
    if (Config[idx]['mobile']) {
      life.push(<li key={idx}><Link to={"/" + Config[idx]['path']} onClick={hideByClick}>{Config[idx]['title']['en']}</Link></li>);
    } else {
      advance.push(<li key={idx}><Link to={"/" + Config[idx]['path']} onClick={hideByClick}>{Config[idx]['title']['en']}</Link></li>);
    }
  }

  let showMenu = () => {
    document.getElementById("header-nav-backdrop").classList.add("show");
    document.getElementById("header-nav-area").classList.add("show");
  }
  let hideMenu = () => {
    document.getElementById("header-nav-backdrop").classList.remove("show");
    document.getElementById("header-nav-area").classList.remove("show");
  }

  return (
    <div id="header-nav">
      <div id="header-nav-backdrop" onClick={hideMenu}></div>
      <div id="header-nav-area">
        <Link to="#" className="mobile-nav cross" onClick={hideMenu} aria-label="Hide Menu"><FontAwesomeIcon icon="fa-regular fa-circle-xmark" alt="Hide Menu" /></Link>
        <ul>{life}{advance}</ul>
      </div>
      <Link to="#" className="mobile-nav nav-btn" onClick={showMenu} aria-label="Open Menu"><FontAwesomeIcon icon="fa-solid fa-bars" alt="Open Menu" /></Link>
    </div>
  )
};

export default Nav;